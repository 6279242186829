<template>
  <router-view />
</template>

<script>
import { mapGetters } from "vuex";
import equal from "fast-deep-equal";

export default {
  name: "KuisionerContainer",
  computed: {
    ...mapGetters({
      axios: "axios",
    }),
    programId() {
      return this.$route.params.programId;
    },
  },
  watch: {
    programId: {
      immediate: true,
      async handler(programId, old) {
        if (equal(programId, old)) return;
        // await this.$store
        //   .dispatch("kuisioner/getV2Client", programId)
        //   // .then((r) => console.log("r", r));
        // await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
          // .then((r) => console.log("r2", r));
      },
    },
  },
  async mounted() {
    // await this.$store.dispatch("kuisioner/get", this.programId);
    // await this.$store.dispatch("kuisioner/getV2ClientAnswer", this.programId)
    // .then(r => console.log("resp", r))
    // .then(r => console.log('get draft', r))
  },
};
</script>
